import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const Available = ({ resources }) => {
  // Extract domain names and head counts, grouped by domain
  const domainGrouped = resources.reduce((result, item) => {
    const domains = item.name
      .split(",")
      .map((namePart) => {
        const match = namePart.trim().match(/(.+?)\s+(\d+)\s*\(HCs?\)/i);
        return match
          ? {
              domain: match[1].trim(),
              HC: parseInt(match[2], 10),
              position: item.position,
            }
          : null;
      })
      .filter(Boolean);

    domains.forEach(({ domain, HC, position }) => {
      if (!result[domain]) {
        result[domain] = {};
      }
      if (!result[domain][position]) {
        result[domain][position] = 0;
      }
      result[domain][position] += HC;
    });

    return result;
  }, {});

  // Convert the result to an array format (optional)
  const groupedArray = Object.entries(domainGrouped).map(
    ([domain, positions]) => ({
      domain,
      positions: Object.entries(positions).map(([position, HC]) => ({
        position,
        HC,
      })),
    })
  );

  const getHCByLevel = (positions) => {
    const levels = { 4: "", 3: "", 2: "", 1: "", OS: "" };
    positions.forEach(({ position, HC }) => {
      if (levels[position] !== undefined) {
        levels[position] = HC;
      }
    });
    return levels;
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Domain</TableCell>
            <TableCell align="center">4</TableCell>
            <TableCell align="center">3</TableCell>
            <TableCell align="center">2</TableCell>
            <TableCell align="center">1</TableCell>
            <TableCell align="center">OS</TableCell>
            <TableCell align="center">Total HC</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedArray.map((item) => {
            const levels = getHCByLevel(item.positions);
            const totalHC = Object.values(levels).reduce(
              (acc, hc) => acc + (hc || 0),
              0
            );

            return (
              <TableRow key={item.domain}>
                <TableCell>{item.domain}</TableCell>
                <TableCell align="center">{levels[4]}</TableCell>
                <TableCell align="center">{levels[3]}</TableCell>
                <TableCell align="center">{levels[2]}</TableCell>
                <TableCell align="center">{levels[1]}</TableCell>
                <TableCell align="center">{levels.OS}</TableCell>
                <TableCell align="center">{totalHC}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Available;
